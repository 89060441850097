import { template as template_b4273515a73948a89d6e9d62e208a643 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b4273515a73948a89d6e9d62e208a643(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
