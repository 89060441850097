import { template as template_70e051ba922f43a6b8fa7354e3e2b604 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_70e051ba922f43a6b8fa7354e3e2b604(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
